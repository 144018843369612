import {getLocalizedStrings} from './localization-store';

/**
 * Retrieves the locale string.
 * This is a combination of the language and country ISO codes.
 * The values are retrieved from the localized strings store.
 * Defaults to en-US if no localized strings are set.
 *
 * @returns The locale string in the format "[languageIsoCode]-[countryIsoCode]".
 */
export function useLocale() {
  const strings = getLocalizedStrings();
  return `${strings.languageIsoCode ?? 'en'}-${strings.countryIsoCode ?? 'US'}`;
}
